import React, { Component } from 'react'
import * as JsSearch from 'js-search'
import Link from 'gatsby-link'
import { MDBRow, MDBCol, MDBContainer, MDBIcon } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import moment from 'moment'

class ClientSearch extends Component {
  state = {
    isLoading: true,
    searchResults: [],
    search: null,
    isError: false,
    indexByTemplate: false,
    indexByName: false,
    indexByTitle: false,
    indexBySubtitle: false,
    indexByDate: false,
    indexByCategory: false,
    indexByPlatform: false,
    indexByHeadline: false,
    indexByBody: false,
    indexBySection: false,
    termFrequency: true,
    removeStopWords: false,
    searchQuery: '',
    selectedStrategy: '',
    selectedSanitizer: '',
  }
  /**
   * React lifecycle method that will inject the data into the state.
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.search === null) {
      const { engine } = nextProps
      return {
        indexByTemplate: engine.TemplateIndex,
        indexByName: engine.NameIndex,
        indexByTitle: engine.TitleIndex,
        indexBySubtitle: engine.SubtitleIndex,
        indexByDate: engine.DateIndex,
        indexByCategory: engine.CategoryIndex,
        indexByPlatform: engine.PlatformIndex,
        indexByHeadline: engine.HeadlineIndex,
        indexByBody: engine.BodyIndex,
        indexBySection: engine.SectionIndex,
        termFrequency: engine.SearchByTerm,
        selectedSanitizer: engine.searchSanitizer,
        selectedStrategy: engine.indexStrategy,
      }
    }
    return null
  }
  async componentDidMount() {
    this.rebuildIndex()
    this.searchInput.focus()
  }

  /**
   * rebuilds the overall index based on the options
   */
  rebuildIndex = () => {
    const {
      selectedStrategy,
      selectedSanitizer,
      removeStopWords,
      termFrequency,
      indexByTemplate,
      indexByName,
      indexByTitle,
      indexBySubtitle,
      indexByDate,
      indexByCategory,
      indexByPlatform,
      indexByHeadline,
      indexByBody,
      indexBySection,
    } = this.state
    const { content } = this.props

    const dataToSearch = new JsSearch.Search(['id'])

    if (removeStopWords) {
      dataToSearch.tokenizer = new JsSearch.StopWordsTokenizer(
        dataToSearch.tokenizer
      )
    }
    /**
     * defines an indexing strategy for the data
     * read more about it here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    if (selectedStrategy === 'All') {
      dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    }
    if (selectedStrategy === 'Exact match') {
      dataToSearch.indexStrategy = new JsSearch.ExactWordIndexStrategy()
    }
    if (selectedStrategy === 'Prefix match') {
      dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
    }

    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     */
    selectedSanitizer === 'Case Sensitive'
      ? (dataToSearch.sanitizer = new JsSearch.CaseSensitiveSanitizer())
      : (dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer())
    termFrequency === true
      ? (dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex(['id',]))
      : (dataToSearch.searchIndex = new JsSearch.UnorderedSearchIndex())

    // sets the index attribute for the data
    if (indexByTemplate) {
      dataToSearch.addIndex(['template'])
    }
    // sets the index attribute for the data
    if (indexByName) {
      dataToSearch.addIndex(['name'])
    }
    // sets the index attribute for the data
    if (indexByTitle) {
      dataToSearch.addIndex(['title'])
    }
    // sets the index attribute for the data
    if (indexBySubtitle) {
      dataToSearch.addIndex(['subtitle'])
    }
    // sets the index attribute for the data
    if (indexByDate) {
      dataToSearch.addIndex(['date'])
    }
    // sets the index attribute for the data
    if (indexByCategory) {
      dataToSearch.addIndex(['category'])
    }
    // sets the index attribute for the data
    if (indexByPlatform) {
      dataToSearch.addIndex(['platform'])
    }
    // sets the index attribute for the data
    if (indexByHeadline) {
      dataToSearch.addIndex(['headline'])
    }
    // sets the index attribute for the data
    if (indexByBody) {
      dataToSearch.addIndex(['body'])
    }
    // sets the index attribute for the data
    if (indexBySection) {
      dataToSearch.addIndex(['section'])
    }

    dataToSearch.addDocuments(content) // adds the data to be searched

    this.setState({ search: dataToSearch, isLoading: false })
  }
  /**
   * handles the input change and perform a search with js-search
   * in which the results will be added to the state
   */
  searchData = e => {
    const { search } = this.state
    const queryResult = search.search(e.target.value)
    this.setState({ searchQuery: e.target.value, searchResults: queryResult })
  }
  handleSubmit = e => {
    e.preventDefault()
  }
   render() {
    const { searchResults, searchQuery } = this.state
    /*const { content } = this.props
    const queryResults = searchQuery === "" ? content : searchResults*/
    const queryResults = searchQuery === '' ? searchResults : searchResults
    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol>
            <form
              onSubmit={this.handleSubmit}
              className="form-inline mt-2 mb-5 justify-content-center"
            >
              <MDBIcon icon="search" />
              <input
                className="form-control form-control-lg ml-3 w-75 text-xs-large text-large"
                type="text"
                ref={(input) => { this.searchInput = input }} 
                id="Search"
                value={searchQuery}
                onChange={this.searchData}
                placeholder="Please enter your search phrase..."
              />
            </form>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol>
            <p className="mt-2 mb-5 font-alt font-w-700 text-xs-large text-large text-uppercase text-center">
              Number of results: {queryResults.length}
            </p>
          </MDBCol>
        </MDBRow>

        {queryResults.map(item => {
          let pageLink = ""
          switch(item.name) {
            case "index": 
              pageLink = "/"
              break 
            case "news":
              pageLink = "/news/" + item.slug + "/"
              break
            case "contact": 
              pageLink = "/contact/"
              break                            
            case "events": 
              pageLink = "/events/" + item.slug + "/"
              break
            case "corry-hong-founder-and-ceo": 
              pageLink = "/about/corry-hong-founder-and-ceo/"
              break              
            case "leadership": 
              pageLink = "/about/leadership/"
              break
            case "products": 
              pageLink = "/products/"
              break              
            case "support": 
              pageLink = "/support/"
              break               
            default:
              pageLink = item.slug
          }
          return (
            <MDBRow key={`row_${item.id}`}>
              <MDBCol md="1">
                <Link to={pageLink} className="ficon-sl d-none d-sm-block" > <FontAwesomeIcon icon={faChevronRight} /> </Link>
              </MDBCol>

              <MDBCol md="11">
                <p className="font-alt font-w-400 text-small text-uppercase mb-2">
                  {item.template === "news" ? item.template + ": " + moment(item.date).format('MMM DD, YYYY') : item.template}
                </p>
                <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium"> <Link to={pageLink} className="effect-noline"> {item.title} </Link> </h3>
                <p className="font-w-400 text-medium">
                  {item.subtitle}
                </p>

                <hr className="my-5" />
              </MDBCol>
            </MDBRow>
          )
        })}
      </MDBContainer>
    )
  }
}

export default ClientSearch